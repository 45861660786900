

















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import FilterDapp from '@/components/filters/FilterDapp.vue'
import FilterToggle from '@/components/FilterToggle.vue'
import DappCard from '@/components/cards/DappCard.vue'
import {DappCollection} from '@/model/collection/DappCollection'
import NavHeaderMenu from '@/components/NavHeaderMenu.vue'
import DappsHeader from '@/components/DappsHeader.vue'

@Component({
  components: {
    NavHeaderMenu,
    DappCard,
    FilterToggle,
    FilterDapp,
    NavbarMenu,
    AppFooter,
    DappsHeader,
  },
})
export default class DappsOverview extends Vue {
  @Prop({type: String, default: null}) privateCategorySlug!: string | null
  @Prop({type: Boolean, required: true}) isDapp!: boolean
  @Prop({type: String, required: true}) toOverviewView!: string

  collection = new DappCollection()

  async mounted() {
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    this.collection.hasContract = this.isDapp
    this.collection.orderBy = 'publishedDate'
    this.collection.asc = false
    this.collection.requestName = 'overview'
    this.collection.perPage = 30
    this.collection.privateCategorySlug = this.privateCategorySlug
    this.collection.private = this.collection.isPrivate
  }

  async populateResources() {
    const promises: Promise<any>[] = [this.collection.expand()]

    await Promise.all(promises)
  }

  @Watch('$route.name')
  async routeNameEvent(name: string) {
    await this.collection.queryToFirstPage()
  }
}
